import { Badge } from '../Badge';
import { getDividerClasses } from './helper';
import { DividerProps } from './types';

const Divider = (props: DividerProps) => {
  const { type = 'horizontal', className, ...restProps } = props;

  const finalClassName = [getDividerClasses(type), className].join(' ');

  const renderContent = () => {
    switch (type) {
      case 'dot':
        return <Badge purpose="active-status" active-status="away" size={10} />;
      default:
        return <hr {...restProps} className={finalClassName} />;
    }
  };
  return renderContent();
};

export default Divider;
