import { useEffect } from 'react';

const isBrowser = typeof window !== `undefined`;

function getScrollPosition(): {
  x: number;
  y: number;
} {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = window.document?.documentElement || window.document?.body;

  return { x: target?.scrollLeft || 0, y: target?.scrollTop || 0 };
}

export function useScrollPosition(
  effect: ({ scrollY, scrollX }: { scrollY: number; scrollX: number }) => void,
  wait?: number
) {
  useEffect(() => {
    let throttleTimeout: ReturnType<typeof setTimeout> | null = null;

    const handleScroll = () => {
      const position = getScrollPosition();
      const callBack = () => {
        effect({ scrollY: position.y, scrollX: position.x });
        throttleTimeout = null;
      };

      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener('scroll', handleScroll, true);

    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);
}
