import useGetLatestSubscription from '@/hooks/subscription/useGetLatestSubscription';
import { useScreen } from '@/hooks/useScreen';
import useToggle from '@/hooks/useToggle';
import { hasUserSubscripted } from 'common/subscription/checkSubscriptionResource';
import { Button, ButtonProps } from 'design-system/components/Button';
import { GenericIconName, IconVariant } from 'design-system/components/Icon';
import dynamic from 'next/dynamic';
import { twMerge } from 'tailwind-merge';

const SubscriptionModal = dynamic(
  () => import('../elements/subscription/SubscriptionModal')
);

export type UpgradeAccountButtonProps = ButtonProps & {
  ignoreMobile?: boolean;
  forceMobile?: boolean;
  disableChangeWhenPackageActive?: boolean;
};

const UpgradeAccountButton = (props: UpgradeAccountButtonProps) => {
  const {
    className,
    children,
    ignoreMobile = false,
    forceMobile = false,
    disableChangeWhenPackageActive,
    ...restProps
  } = props;

  const [open, toggle] = useToggle();
  const { data } = useGetLatestSubscription({});

  const changeDisplayCondition =
    hasUserSubscripted(data) && !disableChangeWhenPackageActive;

  const { isMobile } = useScreen();
  const finalClassName = twMerge(
    'bg-Other/Upgradeaccount text-black',
    changeDisplayCondition
      ? 'border border-Other/Upgradeaccount-border bg-clip-text text-transparent'
      : '',
    className
  );

  const icon: {
    name: GenericIconName;
    variant?: IconVariant | undefined;
    viewBox?: string | undefined;
  } = restProps.icon ?? {
    name: changeDisplayCondition ? 'subscripted-diamond' : 'diamond',
    variant: 'solid',
    viewBox: '0 0 20 20',
  };

  return (
    <>
      {(isMobile && !ignoreMobile) || forceMobile ? (
        <Button
          {...restProps}
          onClick={toggle}
          mode="icon-only"
          icon={icon}
          className={finalClassName}
        />
      ) : (
        <Button
          {...restProps}
          startIcon={icon}
          onClick={toggle}
          className={finalClassName}
        >
          <p>
            {changeDisplayCondition
              ? data?.subscription?.packageName
              : children}
          </p>
        </Button>
      )}

      {open && <SubscriptionModal open={open} toggle={toggle} />}
    </>
  );
};

export default UpgradeAccountButton;
