import { useScreen } from '@/hooks/useScreen';
import dynamic from 'next/dynamic';
import { memo } from 'react';
import { NavigationsProps } from './types';

const BottomNavigations = dynamic(() => import('./BottomNavigations'));
const NavigationBar = dynamic(() => import('./NavigationBar'), { ssr: false });

const Navigations = ({
  hideBottomBar,
  ...props
}: NavigationsProps & {
  hideBottomBar?: boolean;
}) => {
  const { isDesktop } = useScreen();

  return !isDesktop ? (
    !hideBottomBar ? (
      <BottomNavigations {...props} />
    ) : (
      <></>
    )
  ) : (
    <NavigationBar {...props} />
  );
};

export default memo(Navigations);
