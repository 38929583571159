export const ZALO_OA_SUPPORT = 'https://zalo.me/3295529987771317934';
export const MYDOCCEN_URL = {
  COURSE_DETAIL_URL: (gid: string, cid: string) =>
    `${
      process.env.MYDOCCEN_DOMAIN || 'https://www.mydoccen.vn'
    }/my-groups/${gid}/course/${cid}`,
};
export const FACEBOOK_SUPPORT =
  'https://www.facebook.com/messages/t/103205589237435';

export const PAYMENT_URL = ({
  uid,
  orderId,
  url,
}: {
  uid: string;
  orderId: string;
  url: string;
}) =>
  `${process.env.PAYMENT_URL}/payment/checkpoint/?userId=${uid}&orderId=${orderId}&returnUrl=${url}`;

export const REGISTER_PRO_ACCOUNT_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSfbbEhz1R09LvYZsv02IX3am7bqUR6H1_VLxXXB8_P9p2P8rA/viewform?usp=sf_link';

export const EVENT_URL = 'http://event.doccen.vn/megawecare';
export const VERIFY_ACCOUNT_URL =
  'https://www.doccencorp.com/2023/04/09/doccen-trien-khai-dich-vu-xac-thuc-tai-khoan-tick-xanh-mien-phi/';
export const GUIDE_VERIFY_ACCOUNT_URL =
  'https://doccencorp.com/2023/09/15/huong-dan-dang-ky-va-quyen-loi-tai-khoan-xac-thuc-doccen/';
export const DOCCENCOORP_URL = 'http://Doccencorp.com';
export const DOCCEN_ZALO_CONTACT_URL =
  'https://zalo.me/doccenvn?fbclid=IwAR2A3mOQIybsS98UI3pNtHjT_xhmqyFLww6IG8xc4d31161eT9BsIYBlRXo';
export const DOCCEN_TIKTOK = 'https://www.tiktok.com/@doccen.vn';
export const DOCCEN_FACEBOOK = 'https://www.facebook.com/doccenvn';
export const DOCCEN_ZALO = 'https://zalo.me/doccenvn';
export const SURVEY_URL = 'https://khaosat.me/survey/gop-y-ung-dung-60384fe';
export const INFO_VERIFY_ACCOUNT_URL =
  'https://doccencorp.com/2023/09/15/huong-dan-dang-ky-va-quyen-loi-tai-khoan-xac-thuc-doccen/';
export const REPORT_DOCCEN_AI_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSesvd6xRIAx2d546MDaue8EIUxSO-sXShghZARa-othoqqK_Q/viewform?pli=1';
export const DOCCEN_AI_HOW_TO_USE_URL = 'https://www.facebook.com/doccenai/';
