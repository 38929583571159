import { Badge as BadgeMUI } from '@mui/material';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';
import { Badge } from '../Badge';
import GenericIcon from '../Icon/GenericIcon';
import { Typography } from '../Typography';
import { getIconButtonClassName } from './helper';
import { IconButtonProps } from './types';

const IconButton = (props: IconButtonProps) => {
  const {
    icon,
    hasBadge,
    label,
    disabled = false,
    activated = false,
    size = '32',
    resting = false,
    className,
    onClick,
  } = props;

  const finalClassName = twMerge(
    getIconButtonClassName(disabled, resting, activated),
    className
  );

  return (
    <div
      role="presentation"
      tabIndex={0}
      className="group w-fit rounded-[4px] focus-visible:border focus-visible:border-Primary/Border focus-visible:outline-none"
      onClick={onClick}
    >
      <button
        type="button"
        tabIndex={-1}
        className={finalClassName}
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        <BadgeMUI
          badgeContent={
            hasBadge ? (
              <Badge
                purpose="active-status"
                active-status="offline"
                size={10}
              />
            ) : null
          }
        >
          <GenericIcon
            iconName={icon.name}
            variant={icon.variant || 'outline'}
          />
        </BadgeMUI>
      </button>
      {label && (
        <Typography
          variant="button2"
          className="text-center text-Text/Lightbackground/Secondary"
        >
          {label}
        </Typography>
      )}
    </div>
  );
};

export default memo(IconButton);
