import { DoccenAiSubscriptionsBenefit } from '@/constant/subscription';
import {
  ESubscriptionPackage,
  EUserSubscriptionStatus,
  ILastestSubscriptionPlanResponse,
  ISubscriptionBenefit,
} from '@/interfaces/subscription.interface';

export const checkSubscriptionResource = (
  latestSubscription: ILastestSubscriptionPlanResponse | undefined
): ISubscriptionBenefit => {
  if (
    latestSubscription &&
    latestSubscription.subscription?.packageName &&
    latestSubscription.subscription?.status === EUserSubscriptionStatus.Active
  ) {
    return DoccenAiSubscriptionsBenefit[
      latestSubscription.subscription.packageName
    ];
  }

  return {
    document: {
      maxPageCountForAI: 0,
      accessToDocument: false,
      maxFileSizeForAI: 0,
    },
    course: {
      accessToCourse: false,
    },
    collection: {
      accessToCollection: false,
    },
  };
};

export const hasUserSubscriptedIdealPlan = (
  latestSubscription: ILastestSubscriptionPlanResponse | undefined
): boolean => {
  const idealPlan: ESubscriptionPackage = ESubscriptionPackage.Premium;
  return (
    latestSubscription?.subscription?.packageName === idealPlan &&
    latestSubscription.subscription.status === EUserSubscriptionStatus.Active
  );
};

export const hasUserSubscripted = (
  latestSubscription: ILastestSubscriptionPlanResponse | undefined
): boolean => {
  return (
    latestSubscription?.subscription?.status === EUserSubscriptionStatus.Active
  );
};
