export const moneyFormat = (moneyNumber: number) => {
  if (Number.isNaN(moneyNumber)) return '';
  return moneyNumber.toLocaleString().replaceAll(',', '.');
};

export const parseMoneyFormat = (formattedString: string) => {
  const numberString = formattedString.replaceAll('.', '');
  const parsedNumber = parseInt(numberString);
  return parsedNumber;
};

export const convert2CharactorNumber = (value: number) => {
  return value < 10 ? `0${value}` : value;
};
