import { DividerType } from './types';

export const getDividerClasses = (type: DividerType): string => {
  switch (type) {
    case 'horizontal':
      return 'bg-Other/Outlinedborder-stroke w-full h-[1px]';
    default:
      return 'bg-Other/Outlinedborder-stroke w-[1px] h-full flex-shrink-0 h-auto self-stretch';
  }
};
