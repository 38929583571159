export const getIconButtonClassName = (
  disabled: boolean,
  resting: boolean,
  activated: boolean
): string => {
  return [
    'mx-auto flex items-center justify-center text-Action/Active rounded-full cursor-pointer group-focus-visible:bg-Primary/Other/Hover md:active:bg-Action/DC/Selected active:bg-Action/DC/Selected active:text-Primary/Pressed hover:bg-transparent md:hover:bg-Primary/Other/Hover p-2',
    disabled && 'text-Text/Lightbackground/Disabled pointer-events-none',
    resting && 'text-Primary/Main',
    activated && 'text-Primary/Pressed bg-Action/DC/Selected',
  ].join(' ');
};
