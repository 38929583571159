import { twMerge } from 'tailwind-merge';
import { Typography } from '../Typography';
import { RibbonBadgeTypes } from './types';

const RibbonBadge = (props: RibbonBadgeTypes) => {
  return (
    <div
      {...props}
      className={twMerge(
        'relative rounded-[8px_5px_0px_8px] bg-[#EF1D00] p-[3px_8px]',
        props.className
      )}
    >
      <Typography
        variant={'table-heading'}
        className="text-[12px] leading-[12px] tracking-[-0.4px] text-Text/Darkbackground/Primary"
      >
        {props.children}
      </Typography>
      <div className="absolute right-0 top-full">
        <svg
          width="4"
          height="5"
          viewBox="0 0 4 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_8931_111627)">
            <path
              d="M4 0C4 0 -3.3719 0 -0.212762 0C2.94638 0 4 5 4 5V0Z"
              fill="#EF1D00"
            />
          </g>
          <defs>
            <clipPath id="clip0_8931_111627">
              <rect width="4" height="5" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default RibbonBadge;
