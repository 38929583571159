import { makeStyles } from '@mui/styles';
import dynamic from 'next/dynamic';
import { twMerge } from 'tailwind-merge';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import { getRoleBadgeClasses } from './helper';
import { RolesBadgeProps, RolesBadgeTypes } from './types';

const Tooltip = dynamic(() => import('@mui/material/Tooltip'));

const useStyles = makeStyles({
  tooltip: {},
  popper: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#333333',
      color: '#FBFBFB',
      fontSize: '12px',
      lineHeigth: '16px',
      fontWeight: '500',
      borerRadius: '4px',
      padding: '6px 10px',
    },
  },
});

const RolesBadge = (props: RolesBadgeProps) => {
  const { type = 'new', className, ...restProps } = props;

  const finalClassName = twMerge(
    'h-[17px] px-[6px] w-fit flex items-center rounded-[100px] border-[0.5px]',
    getRoleBadgeClasses(type),
    className
  );

  const classes = useStyles();

  const renderContent = (type: RolesBadgeTypes) => {
    switch (type) {
      case 'new':
        return <Typography variant="tab">Mới</Typography>;
      case 'verified':
        return (
          <Tooltip
            placement="top-start"
            title={'Doccen đã xác nhận rằng tài khoản này là thật.'}
            classes={{
              popper: classes.popper,
            }}
          >
            <Icon
              iconName="badge-tick"
              width={17}
              height={17}
              className="text-Primary/Main"
            />
          </Tooltip>
        );
      case 'premium':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
          >
            <g clipPath="url(#clip0_4772_47630)">
              <path
                opacity="0.5"
                d="M12.9032 6.9756L12.7658 8.4372C12.539 10.8444 12.4256 12.0486 11.7158 12.774C11.0072 13.5 9.94401 13.5 7.81641 13.5H6.18441C4.05741 13.5 2.99421 13.5 2.28441 12.774C1.57461 12.048 1.46181 10.8444 1.23501 8.4372L1.09701 6.9756C0.989013 5.8284 0.935013 5.2542 1.13181 5.0172C1.18129 4.95541 1.24259 4.90411 1.31213 4.8663C1.38167 4.82849 1.45806 4.80493 1.53681 4.797C1.82241 4.7718 2.18061 5.1798 2.89761 5.9958C3.26841 6.4182 3.45381 6.6288 3.66021 6.6618C3.77549 6.67977 3.89351 6.66087 3.99741 6.6078C4.18881 6.5118 4.31541 6.2508 4.57041 5.7294L5.91261 2.979C6.39381 1.9932 6.63441 1.5 7.00041 1.5C7.36641 1.5 7.60701 1.9932 8.08821 2.979L9.43041 5.7294C9.68481 6.2514 9.81201 6.5118 10.0034 6.6078C10.109 6.6612 10.2254 6.6798 10.3406 6.6618C10.547 6.6288 10.7324 6.4182 11.1032 5.9958C11.8202 5.1798 12.1784 4.7718 12.464 4.797C12.5428 4.80493 12.6192 4.82849 12.6887 4.8663C12.7582 4.90411 12.8195 4.95541 12.869 5.0172C13.0652 5.2542 13.0112 5.8284 12.9032 6.9756Z"
                fill="#FFB400"
              />
              <path
                d="M7.57156 7.91935L7.51276 7.81375C7.28476 7.40455 7.17076 7.19995 7.00036 7.19995C6.82996 7.19995 6.71596 7.40395 6.48796 7.81375L6.42916 7.91935C6.36436 8.03575 6.33196 8.09335 6.28156 8.13175C6.23056 8.17015 6.16756 8.18455 6.04156 8.21275L5.92756 8.23915C5.48476 8.33935 5.26336 8.38915 5.21056 8.55835C5.15776 8.72815 5.30896 8.90455 5.61076 9.25735L5.68876 9.34855C5.77456 9.44875 5.81776 9.49855 5.83696 9.56095C5.85616 9.62335 5.84956 9.68995 5.83696 9.82375L5.82496 9.94555C5.77936 10.4166 5.75656 10.6524 5.89396 10.7568C6.03196 10.8612 6.23956 10.7658 6.65416 10.575L6.76096 10.5258C6.87916 10.4718 6.93796 10.4442 7.00036 10.4442C7.06276 10.4442 7.12156 10.4718 7.23976 10.5258L7.34656 10.575C7.76116 10.7664 7.96876 10.8612 8.10676 10.7568C8.24476 10.6524 8.22136 10.4166 8.17576 9.94555L8.16376 9.82375C8.15116 9.68995 8.14456 9.62335 8.16376 9.56095C8.18296 9.49915 8.22616 9.44875 8.31196 9.34855L8.38996 9.25735C8.69176 8.90455 8.84296 8.72815 8.79016 8.55835C8.73736 8.38915 8.51596 8.33935 8.07316 8.23915L7.95916 8.21275C7.83316 8.18455 7.77016 8.17075 7.71916 8.13175C7.66876 8.09335 7.63636 8.03575 7.57156 7.91935Z"
                fill="#ED9500"
              />
              <circle cx="1" cy="4.5" r="1" fill="#ED9500" />
              <circle cx="13" cy="4.5" r="1" fill="#ED9500" />
              <circle cx="7" cy="1.5" r="1" fill="#ED9500" />
            </g>
            <defs>
              <clipPath id="clip0_4772_47630">
                <rect
                  width="15"
                  height="15"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        );
      case 'enterprise':
        return (
          <Icon
            iconName="enterprise"
            width={17}
            height={17}
            className="text-[#9747FF]"
          />
        );
      case 'cme':
        return (
          <div className="flex items-center gap-[2px]">
            <Icon iconName="certificate" width={14} height={14} />
            <Typography variant="tab">Cấp chứng chỉ CME</Typography>
          </div>
        );
      case 'cpe':
        return (
          <div className="flex items-center gap-[2px]">
            <Icon iconName="certificate" width={14} height={14} />
            <Typography variant="tab">Cấp chứng chỉ CPE</Typography>
          </div>
        );
    }
  };

  return (
    <div {...restProps} className={finalClassName}>
      {renderContent(type)}
    </div>
  );
};

export default RolesBadge;
