import {
  ESubscriptionDuration,
  ESubscriptionPackage,
  ISubscriptionBenefit,
} from '@/interfaces/subscription.interface';
import { moneyFormat } from 'common/numberHelpers';
import { ZALO_OA_SUPPORT } from 'configs/externalLink';

export const SUBSCRIPTION_BENEFITS: {
  benefit: React.ReactNode | string;
  tooltip?: string;
  values: ESubscriptionPackage[];
}[] = [
  {
    benefit: (
      <>
        <strong>8,000+</strong> tài liệu học tập chuyên ngành
      </>
    ),
    tooltip:
      '8,000+ tài liệu học tập: Đầy đủ chuyên ngành từ các trường y khoa hàng đầu Việt Nam.',
    values: Object.values(ESubscriptionPackage),
  },
  {
    benefit: (
      <>
        <strong>3,000+</strong> tài liệu chuyên khảo (Phác đồ, guideline, tạp
        chí khoa học)
      </>
    ),
    tooltip:
      '3,000+ tài liệu chuyên khảo: Bao gồm phác đồ trong nước, guideline quốc tế, tạp chí khoa học trong và ngoài nước.',
    values: Object.values(ESubscriptionPackage),
  },
  {
    benefit: (
      <>
        <strong>700+</strong> đầu sách chuyên khảo dành riêng cho bác sĩ
      </>
    ),
    tooltip: '700+ sách chuyên khảo: Dành riêng cho bác sĩ.',
    values: Object.values(ESubscriptionPackage),
  },
  {
    benefit: (
      <>
        <strong>10,000+</strong> câu hỏi quiz chuyên ngành
      </>
    ),
    tooltip: '10,000+ câu hỏi quiz: Chuẩn hóa từ các trường đại học lớn.',
    values: Object.values(ESubscriptionPackage).filter(
      (item) => item !== ESubscriptionPackage.Advanced
    ),
  },
  {
    benefit: (
      <>
        <strong>300+</strong> khóa học chuyên môn
      </>
    ),
    tooltip:
      '300+ khóa học chuyên môn: Từ giảng viên hàng đầu và các hiệp hội uy tín.',
    values: Object.values(ESubscriptionPackage).filter(
      (item) => item !== ESubscriptionPackage.Advanced
    ),
  },
  {
    benefit: (
      <>
        Truy cập <strong>không giới hạn</strong> DoccenAI
      </>
    ),
    tooltip: 'DoccenAI: Truy cập không giới hạn, hỗ trợ học tập và nghiên cứu.',
    values: Object.values(ESubscriptionPackage).filter(
      (item) => item !== ESubscriptionPackage.Advanced
    ),
  },
  {
    benefit: (
      <>
        Webinar/Seminar khoa học <strong>(CME/CPE)</strong>
      </>
    ),
    tooltip: 'Webinar/Seminar khoa học: Ưu tiên tham gia, có cấp CME/CPE.',
    values: Object.values(ESubscriptionPackage).filter(
      (item) => item !== ESubscriptionPackage.Advanced
    ),
  },
  {
    benefit: (
      <>
        <strong>Trao đổi trực tiếp</strong> với giảng viên và trợ giảng
      </>
    ),
    values: Object.values(ESubscriptionPackage).filter(
      (item) => item !== ESubscriptionPackage.Advanced
    ),
  },
];

export const SUBSCRIPTION_HEADING: {
  [key in ESubscriptionPackage]: {
    pricingFormat: (value?: number) => number | string | React.ReactNode;
    buttonTitle?: string;
    typographyClassName?: string;
    onClick?: () => void;
  };
} = {
  [ESubscriptionPackage.Advanced]: {
    pricingFormat: (value?: number) => `/${moneyFormat(value ?? 0)}đ`,
    typographyClassName: 'text-[28px] leading-[140%]',
    buttonTitle: 'Chọn ngay',
  },
  [ESubscriptionPackage.Premium]: {
    pricingFormat: (value?: number) => `/${moneyFormat(value ?? 0)}đ`,
    typographyClassName: 'text-[28px] leading-[140%]',
    buttonTitle: 'Chọn ngay',
  },
  [ESubscriptionPackage.Enterprise]: {
    pricingFormat: () => '(Trên 10 người)',
    buttonTitle: 'Liên hệ',
    typographyClassName: 'text-[20px] leading-[130%]',
    onClick() {
      window.open(ZALO_OA_SUPPORT);
    },
  },
};

export const DoccenAiSubscriptionsBenefit: {
  [key in ESubscriptionPackage]: ISubscriptionBenefit;
} = {
  [ESubscriptionPackage.Advanced]: {
    document: {
      maxPageCountForAI: 200,
      accessToDocument: true,
      maxFileSizeForAI: 32 * 1024 * 1024,
    },
    course: {
      accessToCourse: false,
    },
    collection: {
      accessToCollection: true,
    },
  },
  [ESubscriptionPackage.Premium]: {
    document: {
      maxPageCountForAI: 2000,
      accessToDocument: true,
      maxFileSizeForAI: 32 * 1024 * 1024,
    },
    course: {
      accessToCourse: true,
    },
    collection: {
      accessToCollection: true,
    },
  },
  [ESubscriptionPackage.Enterprise]: {
    document: {
      maxPageCountForAI: 2000,
      accessToDocument: true,
      maxFileSizeForAI: 32 * 1024 * 1024,
    },
    course: {
      accessToCourse: true,
    },
    collection: {
      accessToCollection: true,
    },
  },
};

export const DailySubscriptionPricing: {
  [key in ESubscriptionPackage]: {
    [key in ESubscriptionDuration]: number | null;
  };
} = {
  [ESubscriptionPackage.Advanced]: {
    [ESubscriptionDuration.ThreeDays]: null,
    [ESubscriptionDuration.OneMonth]: null,
    [ESubscriptionDuration.SixMonths]: null,
    [ESubscriptionDuration.OneYear]: 1525,
  },
  [ESubscriptionPackage.Premium]: {
    [ESubscriptionDuration.ThreeDays]: null,
    [ESubscriptionDuration.OneMonth]: null,
    [ESubscriptionDuration.SixMonths]: null,
    [ESubscriptionDuration.OneYear]: 2050,
  },
  [ESubscriptionPackage.Enterprise]: {
    [ESubscriptionDuration.ThreeDays]: null,
    [ESubscriptionDuration.OneMonth]: null,
    [ESubscriptionDuration.SixMonths]: null,
    [ESubscriptionDuration.OneYear]: null,
  },
};
