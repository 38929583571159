import { Tooltip } from '@mui/material';
import { TooltipsProps } from './types';

const Tooltips = (props: TooltipsProps) => {
  const {
    children,
    title,
    placement = 'right',
    disableInteractive = true,
    ...restProps
  } = props;

  if (!title) return children;

  return (
    <Tooltip
      {...restProps}
      title={title}
      // open
      disableInteractive={disableInteractive}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#333333',
            padding: '6px 10px',
            fontSize: '12px',
            color: '#ffffff',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default Tooltips;
