import { Badge as BadgeMUI } from '@mui/material';
import { twMerge } from 'tailwind-merge';
import { Icon } from '../Icon';
import { capAt99, getBadgeClasses } from './helper';
import { BadgeProps } from './types';

const Badge = (props: BadgeProps) => {
  const {
    size,
    purpose,
    'active-status': activeStatus,
    stroke,
    badgeContent,
    ...restProps
  } = props;

  const finalClassName = getBadgeClasses(purpose, activeStatus, stroke);

  const renderContent = () => {
    switch (purpose) {
      case 'success':
        return (
          <Icon
            width={size}
            height={size}
            iconName="tick-circle"
            variant="solid"
            className="text-Success/Main"
          />
        );
      case 'failed':
        return (
          <Icon
            width={size}
            height={size}
            iconName="close-circle"
            variant="solid"
            className="text-Error/Main"
          />
        );
      case 'skip':
        return (
          <Icon
            width={size}
            height={size}
            iconName="minus-circle"
            variant="solid"
            className="text-Text/Lightbackground/Disabled"
          />
        );
      case 'navigation':
      case 'notification':
        if (size === 10) return null;
        return capAt99(badgeContent || 0);
      default:
        return null;
    }
  };

  return (
    <BadgeMUI
      {...restProps}
      className={twMerge(finalClassName, restProps.className)}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      {renderContent()}
    </BadgeMUI>
  );
};

export default Badge;
