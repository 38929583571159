import UpgradeAccountButton from '@/components/buttons/UpgradeAccountButton';
import { DOCCEN_LOGO_ICON, DOCCEN_TYPO_ICON } from '@/constant/imgImport';
import { SHOW_HEADER_ON_SCROLLING_POINT } from '@/constant/numericParams';
import { useScreen } from '@/hooks/useScreen';
import { useScrollPosition } from '@/hooks/useScreenPosition';
import useToggle from '@/hooks/useToggle';
import { PATH_NAME } from 'configs/pathName';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { forwardRef, memo, useRef, useState } from 'react';
import { Appbar as ClientAppbar } from '../Appbar';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { SearchBar as ClientSearchBar } from '../SearchBar';
import Tooltips from '../Tooltips/Tooltips';
import { Typography } from '../Typography';
import { TopNavigationProps } from './types';

const Popover = dynamic(() => import('@mui/material/Popover'), {
  ssr: false,
});
const Appbar = dynamic<React.ComponentProps<typeof ClientAppbar>>(
  () => import('../Appbar').then((module) => module.Appbar),
  {
    ssr: false,
    loading: () => <div className="w-[375px]" />,
  }
);
const SearchBar = dynamic<React.ComponentProps<typeof ClientSearchBar>>(
  () => import('../SearchBar').then((module) => module.SearchBar),
  {
    ssr: false,
  }
);

const TopNavigations = forwardRef<HTMLElement, TopNavigationProps>(function Foo(
  props,
  ref
) {
  const {
    className,
    type,
    handleClick,
    userInfo,
    listExtandCards,
    search = {
      value: '',
      onChange: () => {},
    },
    rightAdornment,
    ...restProps
  } = props;

  const extendBtnRef = useRef(null);
  const isLoggedIn = type === 'signed-in';
  const router = useRouter();
  const { isMobile, isDesktop } = useScreen();
  const [searchMode, toggleSearchMode] = useToggle();
  const [expanded, toggleExpanded] = useToggle();
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const { value: searchValue, onChange: onChangeSearch } = search;

  useScrollPosition(({ scrollY }) => {
    if (scrollY > SHOW_HEADER_ON_SCROLLING_POINT) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  });

  const finalClassName = [
    'border-b border-Other/Outlinedborder-stroke',
    isMobile ? 'p-[6px]' : 'py-1 px-4',
    isScrolled && 'shadow-elevator1',
    className,
  ].join(' ');

  const submitSearch = () => {
    router.push({
      pathname: PATH_NAME.HOME_FEED_SEARCH,
      query: {
        searchKey: searchValue,
      },
    });
  };

  const handleClickLogo = () => {
    router.push(PATH_NAME.LIBRARY);
  };

  const leftContent = () => {
    return (
      <div className="flex flex-shrink-0 items-center gap-2">
        <div
          className="flex w-fit cursor-pointer items-center"
          onClick={handleClickLogo}
        >
          <Image
            width={40}
            height={40}
            alt="doccen logo"
            src={DOCCEN_LOGO_ICON}
          />
          <div className="-ml-2 block">
            <img alt="doccen typo" src={DOCCEN_TYPO_ICON} />
          </div>
        </div>
        <UpgradeAccountButton>Nâng cấp tài khoản</UpgradeAccountButton>
        {type !== 'edit' && isMobile && (
          <IconButton
            icon={{ name: 'search' }}
            className="text-Text/LightBackground/Secondary"
            onClick={toggleSearchMode}
          />
        )}
      </div>
    );
  };
  const centerContent = () => {
    switch (type) {
      case 'visitor':
      case 'signed-in':
        return (
          !isMobile && (
            <SearchBar
              value={searchValue}
              onChange={onChangeSearch}
              handleSubmit={submitSearch}
            />
          )
        );
    }
  };
  const rightContent = () => {
    if (rightAdornment) return rightAdornment;
    switch (type) {
      case 'visitor':
        return (
          <div className="flex items-center justify-center gap-2">
            {!isMobile && (
              <Tooltips title="Mở rộng">
                <div ref={extendBtnRef}>
                  <IconButton
                    icon={{ name: 'menu' }}
                    size="40"
                    onClick={toggleExpanded}
                  />
                </div>
              </Tooltips>
            )}
            <Button
              size={isMobile ? 'small' : 'medium'}
              emphasis={isMobile ? 'high' : 'medium'}
              onClick={handleClick?.['sign-in']}
            >
              <Typography
                variant={isMobile ? 'button3' : 'button2'}
                className="whitespace-nowrap"
              >
                Đăng nhập
              </Typography>
            </Button>
            {!isMobile && (
              <Button
                emphasis="high"
                size={isMobile ? 'small' : 'medium'}
                onClick={handleClick?.['sign-up']}
              >
                <Typography
                  variant={isMobile ? 'button3' : 'button2'}
                  className="whitespace-nowrap"
                >
                  Đăng ký
                </Typography>
              </Button>
            )}
          </div>
        );
      case 'edit':
        return (
          <div className="flex items-center justify-center gap-2">
            <Button startIcon={{ name: 'close-symbol' }}>
              <Typography variant="button2">Đóng</Typography>
            </Button>
            <Button emphasis="high" startIcon={{ name: 'check' }}>
              <Typography variant="button2">Lưu thay đổi</Typography>
            </Button>
          </div>
        );
      case 'signed-in':
        return (
          <div className="flex items-center gap-2 text-Action/Active">
            {!isMobile && (
              <Tooltips title="Mở rộng">
                <div ref={extendBtnRef}>
                  <IconButton
                    icon={{ name: 'menu' }}
                    size="40"
                    onClick={toggleExpanded}
                  />
                </div>
              </Tooltips>
            )}
            <Tooltips title="Thông báo">
              <div>
                <IconButton
                  icon={{ name: 'notification' }}
                  hasBadge
                  size="40"
                  onClick={handleClick?.notifications}
                />
              </div>
            </Tooltips>
            {/* <IconButton icon="cart" size="40" /> */}
          </div>
        );
    }
  };

  return (
    <>
      <nav
        {...restProps}
        ref={ref}
        className={finalClassName}
        style={{
          clipPath: 'inset(0px 0px -30px 0px)',
        }}
      >
        {searchMode ? (
          <div className="flex items-center gap-2">
            <Button
              emphasis="low"
              size="small"
              mode="icon-only"
              icon={{ name: 'arrow-right-md' }}
              onClick={toggleSearchMode}
            />
            <SearchBar
              value={searchValue}
              onChange={onChangeSearch}
              handleSubmit={submitSearch}
            />
          </div>
        ) : (
          <div className="flex items-center justify-between gap-4">
            {leftContent()}
            {centerContent()}
            {rightContent()}
          </div>
        )}
      </nav>
      {expanded && (
        <Popover
          open={expanded}
          onClose={toggleExpanded}
          anchorEl={extendBtnRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Appbar
            signIn={isLoggedIn}
            userInfo={userInfo}
            listExtandCards={listExtandCards}
            handleClick={handleClick}
          />
        </Popover>
      )}
    </>
  );
});

export default memo(TopNavigations);
