import { BadgeProps, RolesBadgeTypes } from './types';

export const getBadgeClasses = (
  purpose: BadgeProps['purpose'],
  activeStatus: BadgeProps['active-status'],
  stroke: BadgeProps['stroke']
): string => {
  const className: Array<string> = ['text-white rounded-full'];

  if (activeStatus === 'online') {
    className.push('bg-Success/Main');
  }
  if (
    activeStatus === 'offline' ||
    purpose === 'navigation' ||
    purpose === 'notification'
  ) {
    className.push(
      'bg-Error/Main text-white justify-center items-center text-[8px] font-semibold'
    );
  }
  if (activeStatus === 'away') {
    className.push('bg-Text/LightBackground/Disabled');
  }

  if (stroke) {
    className.push('border-[1.5px] border-white');
  }

  return className.join(' ');
};

export function capAt99(number: number) {
  if (number > 100) {
    return `99+`;
  } else {
    return number;
  }
}

export const getRoleBadgeClasses = (type: RolesBadgeTypes) => {
  switch (type) {
    case 'verified':
      return 'border-none !p-0';
    case 'new':
      return 'bg-primary_blue_bg border-Primary/Border text-Primary/Main';
    case 'premium':
      return 'bg-Warning/Lighter border-Warning/Main';
    case 'enterprise':
      return 'bg-[#F5EEFF] border-[#9747FF]';
    case 'cme':
    case 'cpe':
      return 'border-Success/Border text-Success/Main bg-[#D2ECCB4D]';
  }
};
